<template>
  <v-app-bar
    app
    color="success"
    dark
  >
    <v-app-bar-nav-icon @click="drawer = !drawer" />
    <v-toolbar-title style="font-family: NotoSanLao;">
      {{
        $route.name
      }}
    </v-toolbar-title>
    <v-spacer />
  </v-app-bar>
</template>
<script>
export default {
  name: "DefaultBar",
  computed: {
    drawer: {
      get() {
        return this.$store.getters["app/getDrawer"];
      },
      set(value) {
        return this.$store.commit("app/setDrawer", value);
      }
    }
  }
};
</script>
<style lang=""></style>
