<template>
  <v-main>
    <v-container
      fluid
    >
      <router-view />
    </v-container>
  </v-main>
</template>
<script>
export default {
  name: 'DefaultView'
}
</script>
<style lang="">

</style>
